<template>
  <ErrorView :size="size" :vertical="vertical" @retry="onRetry" :status="status" :errorMessage="$t('error.generic')"></ErrorView>
</template>
<script lang="ts" setup>

const platform = usePlatformSettings().platformName;

const props = defineProps<{
  size?: 'small' | 'medium' | 'large';
  onRetry?: () => void;
  status?: string;
  vertical?: boolean;
}>();

const size = ref<'small' | 'medium' | 'large'>(props.size ? props.size : 'large');

</script>
